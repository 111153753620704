<template>
  <v-form ref="childform" @submit.prevent="validateBeforeSubmit">
    <v-card>
      <v-card-title class="headline grey lighten-2" primary-title>Plan details</v-card-title>
      <v-card-text>
        <v-col cols="12">
          <v-menu
            ref="planStartMenu"
            v-model="planStartMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="vm.planStart"
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="vm.planStart"
                label="Plan start"
                v-on="on"
                readonly
                v-validate="'required'"
                data-vv-as="planStart"
                name="planStart"
                :error-messages="errors.collect('planStart')"
              ></v-text-field>
            </template>
            <v-date-picker v-model="vm.planStart" no-title scrollable color="green lighten-1" dark>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="planStartMenu = false">Cancel</v-btn>
              <v-btn text color="primary" @click="$refs.planStartMenu.save(vm.planStart)">OK</v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12">
          <v-menu
            ref="planEndMenu"
            v-model="planEndMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="vm.planEnd"
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="vm.planEnd"
                label="Plan end"
                v-on="on"
                readonly
                v-validate="'required'"
                data-vv-as="planEnd"
                name="planEnd"
                :error-messages="errors.collect('planEnd')"
              ></v-text-field>
            </template>
            <v-date-picker v-model="vm.planEnd" no-title scrollable color="green lighten-1" dark>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="planEndMenu = false">Cancel</v-btn>
              <v-btn text color="primary" @click="$refs.planEndMenu.save(vm.planEnd)">OK</v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12">
          <v-menu
            ref="nextReviewDateMenu"
            v-model="nextReviewDateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="vm.nextReviewDate"
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="vm.nextReviewDate"
                label="Next review date"
                v-on="on"
                readonly
                v-validate="'required'"
                data-vv-as="nextReviewDate"
                name="nextReviewDate"
                :error-messages="errors.collect('nextReviewDate')"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="vm.nextReviewDate"
              no-title
              scrollable
              color="green lighten-1"
              dark
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="nextReviewDateMenu = false">Cancel</v-btn>
              <v-btn
                flat
                color="primary"
                @click="$refs.nextReviewDateMenu.save(vm.nextReviewDate)"
              >OK</v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close">Cancel</v-btn>
        <v-btn color="primary" type="submit">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>


<script>
import Vue from "vue";
import VeeValidate from "vee-validate";
import * as lineItemService from "../../services/lineItemSevice";

Vue.use(VeeValidate);
export default {
  $_veeValidate: {
    validator: "new"
  },
  name: "plan-details",

  data: () => ({
    vm: {},
    lineItemDefinitions: [],
    planStartMenu: false,
    nextReviewDateMenu: false,
    planEndMenu: false
  }),
  mounted() {
    this.init();
  },
  computed: {
    currentCase() {
      return this.$store.getters.currentCase;
    }
  },
  methods: {
    init() {
      const me = this;
      this.vm =
        this.currentCase == undefined ||
        this.currentCase.planDetails == undefined
          ? {}
          : this.currentCase.planDetails;

      lineItemService.list().then(result => {
        result.docs.map(doc => {
          const lineItemDefinition = doc.data();
          lineItemDefinition.id = doc.id;
          me.lineItemDefinitions.push(lineItemDefinition);
        });
      });
    },
    validateBeforeSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          //const startDt = new Date(this.vm.planStart); use line items specific to plan year.
          const startDt = new Date(); //always use most recent line items now
          var found = this.lineItemDefinitions.find(x => {
            const effectiveFromDate = new Date(x.effectiveFromDate);
            const effectiveToDate = new Date(x.effectiveToDate);
            return startDt >= effectiveFromDate && startDt <= effectiveToDate;
          });

          if (found) {
            this.vm.lineItemDefinitionId = found.id;
            this.vm.lineItemDefinitionTitle = found.title;
          } else {
            this.vm.lineItemDefinitionId = this.lineItemDefinitions[0].id;
            this.vm.lineItemDefinitionTitle = this.lineItemDefinitions[0].title;
          }

          this.currentCase.planDetails = this.vm;
          this.$store.dispatch("setCurrentCase", this.currentCase);
          this.$store.dispatch("setToast", {
            message: "Plan details updated",
            color: "green"
          });
          this.close();
          return;
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },
    close() {
      this.$emit("close");
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

